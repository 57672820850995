'use client'

import { Icon, Button } from '@vinted/web-ui'
import { X12 } from '@vinted/monochrome-icons'

import { clickEvent } from '_libs/common/event-tracker/events'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useSession from 'hooks/useSession'

import { ClickableElement } from 'constants/tracking/clickable-elements'

type Props = {
  onClick?: () => void
}

const CloseButton = ({ onClick }: Props) => {
  const { track } = useTracking()
  const { screen } = useSession()
  const translate = useTranslate('shipping_fees_applied_info_banner')

  const handleBannerClose = () => {
    onClick?.()
    track(
      clickEvent({
        target: ClickableElement.CloseShippingInfoBanner,
        screen,
      }),
    )
  }

  return (
    <div>
      <Button
        size={Button.Size.Medium}
        styling={Button.Styling.Flat}
        data-testid="shipping-fees-apply-close-button"
        theme="amplified"
        icon={<Icon name={X12} />}
        onClick={handleBannerClose}
        aria={{ 'aria-label': translate('actions.a11y.close') }}
      />
    </div>
  )
}

export default CloseButton
