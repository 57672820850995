'use client'

import { Cell, Text } from '@vinted/web-ui'
import { useInView } from 'react-intersection-observer'

import useTracking from 'hooks/useTracking'

import { FormattedMessage } from 'components/@support'
import { viewEvent } from '_libs/common/event-tracker/events'
import { ViewableElement } from 'constants/tracking/viewable-elements'
import { navigateToPage } from '_libs/utils/window'
import { MEMBER_PROFILE_URL } from 'constants/routes'

type Props = {
  itemCount: number
  userId: number
  position: number
  onClick?: () => void
}

const ClosetCTA = ({ itemCount, userId, position, onClick }: Props) => {
  const { track } = useTracking()
  const { ref } = useInView({
    threshold: 1,
    onChange: inView => {
      if (!inView) return
      track(
        viewEvent({
          target: ViewableElement.SeeWholeClosetCta,
          targetDetails: JSON.stringify({
            position,
            userIdentifier: userId,
          }),
        }),
      )
    },
  })

  function handleCtaClick() {
    onClick?.()

    navigateToPage(MEMBER_PROFILE_URL(userId))
  }

  return (
    <div ref={ref} className="closet__cta-container" data-testid="closet-cta">
      <div className="closet__cta">
        <Cell
          type={Cell.Type.Navigating}
          chevron={false}
          onClick={handleCtaClick}
          theme="transparent"
          testId="show-all-items"
        >
          <Text
            type={Text.Type.Title}
            theme="muted"
            alignment={Text.Alignment.Center}
            width={Text.Width.Parent}
            as="span"
          >
            <FormattedMessage
              pluralize
              count={itemCount}
              id="closet_promotion.listing.actions.view_all_items"
              values={{ count: itemCount }}
            />
          </Text>
        </Cell>
      </div>
    </div>
  )
}

export default ClosetCTA
